/********** Template CSS **********/
@font-face {
    font-family: BeVietnamPro;
    src: url('../fonts/BeVietnamPro-Regular.ttf');
}
body, html, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: BeVietnamPro;
}
#webpack-dev-server-client-overlay {
    display: none !important;
}

:root {
    --primary: #353F7C;
    --secondary: #0DD3F1;
    --light: #F6F7FC;
    --dark: #15233C;
    --gray: #555555;
}
.text-gray {
    color: var(--gray);
}
.text-primary {
    color: var(--primary);
}
.bg-primary {
    background-color: var(--primary)!important;
}
p.txt-about {
    margin: auto;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

a{
    box-shadow: none !important;
}
button{
    box-shadow: none;
}
/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    transition: .5s;
}
.feature-home .blog-item-list {}
.feature-home .blog-item-list .blog-name, .feature-home .blog-item .blog-name{
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    /* line-height: 20px; */
    padding: 0;
    margin: 0;
    margin-top: 0 !important;
    font-size: 16px;

}
.feature-home .blog-item-list .blog-short-desc, .feature-home .blog-item .blog-short-desc{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.feature-home .blog-item-list img,.feature-home .blog-item img {
    object-fit: cover;
    min-height: 100px;
    min-width: 250px;
    border-radius: 10px;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover,
.btn.btn-secondary,
.btn.btn-outline-secondary:hover {
    color: #FFFFFF;
}

.btn.btn-primary:hover {
    background: var(--secondary);
    border-color: var(--secondary);
}

.btn.btn-secondary:hover {
    background: var(--primary);
    border-color: var(--primary);
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.navbar.sticky-top {
    top: -100px;
    transition: .5s;
}

.navbar .navbar-brand img {
    max-height: 60px;
}

.navbar .navbar-nav .nav-link {
    margin-left: 16px;
    padding: 6px 16px;
    color: var(--primary);
    font-weight: 300;
    outline: none;
    border-radius: 100px;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    background: var(--primary);
    border-color: var(--primary);
    color: var(--light);
    border-radius: 100px;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}
.about-section {
    background-color: #E3F3FF;
}
.about-item-service {
    background-color: var(--light);
    border-radius: 12px;
    padding: 16px;
    gap: 12px;
    height: 200px;
}
.bg-gr {
    padding: 80px 20px 150px;
    height: 565px;
}
.bg-gr-1 {
    background: url("../images/bg-gr1.png") no-repeat center;
    background-size: 100%;
}
.bg-gr-2 {
    background: url("../images/bg-gr2.png") no-repeat center;
    background-size: 100%;
}
.bg-gr-3 {
    background: url("../images/bg-gr3.png") no-repeat center;
    background-size: 100%;
}
.blog-name {
    color: var(--primary);
    font-size: 16px;
}

.colum-right .rounded h2.blog-name {
    margin-top: 0 !important;
}

.blog-name a{
    color: var(--primary);
}
.blog-time {
    color: #888888;
}
.blog-short-desc {
    color: var(--primary);
    font-size: 18px;
}
.blog-short-desc.small {
    font-size: 16px;
}
@media (min-width: 1400px){
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
        max-width: 1170px;
    }
}

@media (max-width: 991.98px) {
    .navbar .navbar-brand img {
        max-height: 45px;
    }

    .navbar .navbar-nav {
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .navbar .nav-item .dropdown-menu {
        padding-left: 30px;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    text-align: start;
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-color: var(--primary);
    border: 15px solid var(--primary);
    border-radius: 50px;
}
.logo-brand-inner {
    /*display: flex;*/
    /*justify-content: center;*/
    /*flex-wrap: wrap;*/
}

.logo-brand-section {
    max-width: 100%;
    overflow: hidden;
}


.logo-brand-inner .slick-slide img {
    margin: auto;
}

.single-logo-item {
    margin: 0;
}
.single-logo-item:hover {
    opacity: 0.7;
}
select.selectpicker {
    display: flex !important;
}

.list-unstyled .text-light a {
    color: #fff;
}

select#select-lange option#select-lange-vn   { background-image:url(../images/flag-vn.png);   }
select#select-lange option#select-lange-vn   { background-image:url(../images/flag-en.png);   }

.navbar-light .navbar-toggler {
    box-shadow: none;
}

.card-service #tabs-nav li a img {
    width: 22px;
    margin-right: 5px;
}
#card-service #tabs-content .col-lg-6 img {
    max-width: 100%;
}
.btn-primary {
    box-shadow:none !important
}
.form-control:focus {
    box-shadow:none !important
}

.form-contact {}
.form-contact .text-danger{}
.form-contact input, .form-contact textarea{
    border-radius: 3px;
    border: 1px solid #ccc;
}
.form-contact .error{
    color: #f00;
    font-size: 13px;
    margin-top: 9px;
}
#select-lange . css-1hb7zxy-IndicatorsContainer{
    padding: 0 !important;
}
#select-lange {
    width:77px;
}
#select-lange .css-1u9des2-indicatorSeparator{
    border-color: transparent !important;
    display: none;
}
#select-lange .css-13cymwt-control{
    border: none;
}
#select-lange .css-t3ipsp-control{
    border-color: transparent !important;
    box-shadow: none !important;
}
.css-t3ipsp-control:hover{

}

#select-lange .lange-current i{
    margin-left: 10px;
}
#select-lange .lange-current img{
    width: 25px;
    height: 16px;
}
#select-lange .css-1fdsijx-ValueContainer {
    opacity: 1;
}
@media (max-width: 920px) {
    .navbar-collapse{
        position: absolute;
        width: 100%;
        top: 60px;
        background: #fff;
        left: 0;
        height: 100dvh;
    }
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }

    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-section .g-5 .col-sm-3 {
        padding-right: 0;
        margin-bottom: 19px;
    }

    .about-section .g-5 .row-counter-up {
        padding-right: 0;
    }

    .feature-home .blog-item-list {
        display: flex !important;
        flex-direction: column;
    }
    .feature-home .blog-item-list .ms-3{
        margin: 0 !important;
    }
    .feature-home .blog-item-list .blog-name, .feature-home .blog-item .blog-name{
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        /* line-height: 20px; */
        padding: 0;
        margin: 0;
        margin-top: 0 !important;

    }
    .feature-home .blog-item-list .blog-short-desc, .feature-home .blog-item .blog-short-desc{
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .feature-home .blog-item-list img,.feature-home .blog-item img {
        object-fit: cover;
        width: 100%;
        margin-bottom: 10px;
    }



}

.page-header {
    background: url(../images/page-header.jpg) center center no-repeat;
    background-size: cover;
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
    font-weight: 500;
}

.page-header .breadcrumb-item a,
.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: #696E77;
}

.page-header .breadcrumb-item a:hover,
.page-header .breadcrumb-item.active {
    color: var(--primary);
}

.about-intro {
    padding: 15px;
}
/*** Facts ***/
@media (min-width: 992px) {
    .container.facts {
        max-width: 100% !important;
    }

    .container.facts .facts-text {
        padding-left: calc(((100% - 960px) / 2) + .75rem);
    }

    .container.facts .facts-counter {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }

    .about-intro {
        padding: 30px;
    }

}

@media (min-width: 1200px) {
    .container.facts .facts-text  {
        padding-left: calc(((100% - 1140px) / 2) + .75rem);
    }

    .container.facts .facts-counter  {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }

    .about-intro {
        padding: 135px;
    }

}

@media (min-width: 1400px) {
    .container.facts .facts-text  {
        padding-left: calc(((100% - 1320px) / 2) + .75rem);
    }

    .container.facts .facts-counter  {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}

/*.container.facts .facts-text {*/
/*    background: linear-gradient(rgba(1, 95, 201, .9), rgba(1, 95, 201, .9)), url(../carousel-1.jpg) center right no-repeat;*/
/*    background-size: cover;*/
/*}*/

/*.container.facts .facts-counter {*/
/*    background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(../carousel-2.jpg) center right no-repeat;*/
/*    background-size: cover;*/
/*}*/

.container.facts .facts-text .h-100,
.container.facts .facts-counter .h-100 {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/*** Service ***/
.service-item {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.service-item .service-icon {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-item .service-icon img {
    max-width: 60px;
    max-height: 60px;
}

.service-item a.btn {
    color: var(--primary);
}

.service-item a.btn:hover {
    color: #FFFFFF;
    background: var(--primary);
    border-color: var(--primary);
}


/*** Appointment ***/
.appointment {
    background: linear-gradient(rgb(231 235 23 / 0%), rgb(218 223 15 / 0%)), url(../images/bg-contact-home.jpg) center center no-repeat;
    background-size: cover;
}


/*** Team ***/
.team-item {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.team-item img {
    transition: .5s;
}

.team-item:hover img {
    transform: scale(1.1);
}

.team-text {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -50px;
    opacity: 0;
    transition: .5s;
}

.team-item:hover .team-text {
    bottom: 0;
    opacity: 1;
}

.team-item a.btn {
    color: var(--primary);
}

.team-item a.btn:hover {
    color: #FFFFFF;
    background: var(--primary);
    border-color: var(--primary);
}


/*** Testimonial ***/
.animated.pulse {
    animation-duration: 1.5s;
}

.testimonial-left,
.testimonial-right {
    position: relative;
}

.testimonial-left img,
.testimonial-right img {
    position: absolute;
    padding: 5px;
    border: 1px dashed var(--primary);
    border-radius: 10px;
}

.testimonial-left img:nth-child(1),
.testimonial-right img:nth-child(3) {
    width: 70px;
    height: 70px;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
}

.testimonial-left img:nth-child(2),
.testimonial-right img:nth-child(2) {
    width: 60px;
    height: 60px;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
}

.testimonial-left img:nth-child(3),
.testimonial-right img:nth-child(1) {
    width: 50px;
    height: 50px;
    bottom: 10%;
    right: 10%;
}

.testimonial-carousel .owl-item img {
    width: 100px;
    height: 100px;
}

.testimonial-carousel .owl-nav {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    margin: 0 5px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: var(--primary);
    border-radius: 10px;
    font-size: 22px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: var(--secondary);
}


/*** Footer ***/
.footer {
    color: var(--primary);
}
.footer .row.g-5{
    width:100%
}
.footer .copyright{
    color: var(--light);
}
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: var(--primary);
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    /*content: "\f105";*/
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #A7A8B4;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    /*letter-spacing: 1px;*/
    box-shadow: none;
}

.footer .btn.btn-square {
    color: var(--primary);
    border: 1px solid #A7A8B4;
}

.footer .btn.btn-square:hover {
    color: var(--secondary);
    border-color: var(--light);
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--secondary);
}

.footer .copyright a:hover {
    color: #FFFFFF;
}

.service-banner-container {
    position: relative;
}
.service-banner-content {
    max-width: 615px;
    position: absolute;
    top: 40%;
    margin: auto;
    left: 0;
    right: 0;
}
.service-banner-content h1.text-white {
    text-transform: capitalize;
}
/* Tabs */
.tabs {
    width: 100%;
    border-radius: 5px 5px 5px 5px;
}
ul#tabs-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    border-bottom: 1px solid #888888;
}
ul#tabs-nav li {
    float: left;
    font-weight: bold;
    margin-right: 2px;
    padding: 8px 20px;
    cursor: pointer;
    /* background: #f00; */
    height: 40px;
}
ul#tabs-nav li:hover,
ul#tabs-nav li.active {
    border-bottom: 2px solid #fff;
    margin-bottom: 0px;
}
#tabs-nav li a {
    text-decoration: none;
    color: #FFF;
}
.tab-content {
    padding: 10px;
}
.job-item {
    border: 1px solid #E6E6E6;
    background: #ffffff;
    padding: 1rem;
}
.btn-inline {
    width: 98px;
    height: 32px;
    padding: 4px 16px 4px 16px;
    border-radius: 100px;
    border: 1px solid #555555;
    display: inline-block;
}


.video-container{
    width: 100%;
    /* height: 100vh; */
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    /* height: 100vh; */
    transform: translate(-50%, -50%);
    aspect-ratio: 16 / 9;
    overflow: hidden;
}
.bootstrap-select .btn-light{
    background: transparent;
    border: none;
}
.bd-example {
    /* background: #ffffff;
    box-shadow: 0px 8px 24px 0px #0000001A;
    width: max-content;
    border-radius: 12px; */
}
.tag {
    padding: 8px;
    margin: 0 5px;
    background: #E3F3FF;
}
.box-search {
    width: 560px;
    border-radius: 8px;
    background: #FFFFFF;
    padding: 12px 16px;
    margin:  auto;
    justify-content: space-between;
}
.search-input {
    width: calc(100% - 150px);
    border: none;
    padding: 8px;
    outline: none;
}
/*@media (min-aspect-ratio: 16/9) {*/
/*    .video-container iframe {*/
/*        !* height = 100 * (9 / 16) = 56.25 *!*/
/*        height: 56.25vw;*/
/*    }*/
/*}*/

/*@media (max-aspect-ratio: 16/9) {*/
/*    .video-container iframe {*/
/*        !* width = 100 / (9 / 16) = 177.777777 *!*/
/*        width: 177.78vh;*/
/*    }*/
/*}*/
.intro-job {
    background: #F1F1F1;
    border-radius: 12px;
}
.intro-job-title {
    width: 250px;
}
.intro-job-inner {
    width: 50%;
}
.intro-job-inner b {
    color: #000000;
}
.btn-round {
    border: 1px solid var(--primary);
}


.dropzone-wrapper {
    border: 1px solid #ced4da;
    color: #ced4da;
    position: relative;
    height: 150px;
    border-radius: 10px;
    cursor: pointer;
}

.dropzone-desc {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 40%;
    top: 50px;
    font-size: 16px;
}

.dropzone,
.dropzone:focus {
    position: absolute;
    outline: none !important;
    width: 100%;
    height: 150px;
    cursor: pointer;
    opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
    background: #ecf0f5;
}

.preview-zone {
    text-align: center;
}

.preview-zone .box {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
}

.articles .blog-short-desc {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.articles .rounded img {
    max-width: 549px;
    max-height: 321px;
    object-fit: fill;
}
.articles .colum-right img {
    width: 150px;
}

.pagination {
    justify-content: center;
    margin:20px;
}

.pagination li{}
.pagination li:last-child a{
    border-radius: 0 10px 10px 0;
}
.pagination li:first-child a{
    border-radius: 10px 0 0 10px;
}
.pagination li a{
    background-color: #fff;
    border: 1px solid #ddd;
    color: #337ab7;
    float: left;
    line-height: 1.42857143;
    margin-left: -1px;
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
}
.pagination li.active a{
    background-color: #337ab7;
    border-color: #337ab7;
    color: #fff;
    cursor: default;
    z-index: 3;
}

.detail-article-content {
    width: 100%;
    overflow: hidden;
}

.detail-article-content img{
    max-width: 100%;
}
.careers{
    width: 100%;
    overflow: hidden;
}
.career-item-footer {}
.career-item-footer .btn-inline.work-time{
    text-transform: capitalize;
}
.career-item-footer .btn-inline{
    width: auto;
    height: auto;
    margin-right: 10px;
}
.articles .no-posts {
    min-height: 300px;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #f00;
}
.job-details .intro-job .row{
    margin-top: 25px;
}
.wrapper-popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background: #212020c7;
    top: 0;
    left: 0;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
}
.wrapper-popup .content-popup{
    width: 100%;
    max-width: 500px;
    /* height: 100%; */
    min-height: 500px;
    background: #fff;
    border-radius: 5px;
    overflow-y: auto;
    margin: auto;
}

.wrapper-popup .content-popup .content-popup-header{
    display: flex;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
}
.wrapper-popup .content-popup .content-popup-header .modal-title{
    flex: 1;
    font-size: 16px;
}
.wrapper-popup .content-popup .content-popup-header .btn-close{}
.wrapper-popup .content-popup .auth-form{
    padding: 10px;
}
.wrapper-popup .content-popup .auth-form .form-group{
    display: flex;
    flex-direction: column;
}
.wrapper-popup .content-popup span.error {
    color: #f00;
    font-size: 13px;
    margin-top: 9px;
}
.wrapper-popup .content-popup .auth-form .form-group label{
    color: #000;
    margin: 10px 0 10px 0;
}
.wrapper-popup .content-popup .auth-form .form-group label span{
    color: #f00;

}
.wrapper-popup .content-popup .auth-form .form-group input{
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    outline: none;
}
.wrapper-popup .content-popup .auth-form .form-group{}
.wrapper-popup .content-popup .auth-form .form-group .dropzone{}
.wrapper-popup .content-popup .auth-form button{
    background-color: var(--primary)!important;
    float: right;
    margin-top: 20px;
    margin-bottom: 10px;
    border: none;
    color: #fff;
    padding: 10px;
    box-shadow: 0 1px 1px 0;
    border-radius: 5px;
    font-size: 14px;
}

.detail-job ul li {
    white-space: unset !important;
}

.wrapper-popup .content-popup .file-select {
    border: 1px solid #ced4da;
    color: #ced4da;
    position: relative;
    height: 54px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
}
.wrapper-popup .content-popup .file-select p{
    margin: 0;
    color: #000;
    flex: 1;
}
.wrapper-popup .content-popup .file-select i{
    width: 35px;
    color: #f00;
    padding: 12px;
}
.about-tab-action .bg-light {
    cursor: pointer;
}
.about-tab-action .bg-light img {
    filter: grayscale(100%);
    filter: hue-rotate(97deg);
}
.tab-service-content {
    position: relative;
}
.tab-service-content .tab-service-content-item {
    padding-right: 20px;
    padding-left: 20px;
    border-left: 2px solid #fff;
    position: relative;

}
.tab-service-content  .tab-service-content-item:first-child {
    padding-left: 0;
    border-left: none;
}
.tab-service-content  .tab-service-content-item:last-child {
    padding-right: 0
}
.tab-service-content  .tab-service-content-item {}
.tab-service-content-item .logo-tab{
    height: 70px;
    display: flex;
    align-items: center;
}
.tab-service-content-item .logo-tab img{

}
.tab-service-content-item p{
    margin-bottom: 35px;
}
.tab-service-content-item a{
    position: absolute;
    bottom: 0;
    color: #fff;
    text-decoration: underline;
}

.tab-service-content-text {
    padding-left: 0;
    padding-right: 0;
}
.card-service-1#card-service {
    background: #353F7C !important;
}

.card-service-2#card-service {
    background: #269365 !important;
}

.card-service-3#card-service {
    background: #2E6C75 !important;
}
.careers .careers-header {
    direction: rtl;
}
.careers .careers-header .left-career-banner {
    direction: ltr;
}
@media (max-width: 480px) {

    .careers .left-career-banner.py-5 {
        padding: 20px 10px 0 10px !important;
        margin-bottom: -20px;
    }
    .careers .container-xxl.py-5 {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    .left-career-banner .bd-example {
        padding: 0 !important;
    }
    .left-career-banner form{width: 100%;}
    .left-career-banner form .col-auto{width: 100%;}

    .left-career-banner form input{}
    .left-career-banner form button{ width: 100%; }
    .careers .right-career-banner {}

    .tab-service-content-item .logo-tab{
        text-align: center;
        width: 100%;

    }
    .tab-service-content-item a {
        position: unset;
        display: block;
        margin-bottom: -5px;
    }
    .tab-service-content-item p {
        margin-bottom: 0;
    }
    .tab-service-content .tab-service-content-item {
        text-align: left;
        border-left: none;
        padding: 10px 10px 10px 10px !important;
        position: relative;
        margin-bottom: 30px;
    }

    .tab-service-content .tab-service-content-item:after{
        /*content: "";*/
        /*width: 100%;*/
        /*height: 2px;*/
        /*border-bottom: 1px solid #fff3cd;*/
        /*padding: 10px;*/
        /*display: block;*/
        /*margin: auto;*/
    }

    .tab-service-content-text {
        padding-left: 10px;
        padding-right: 10px;
    }
    .team-item.our-teams.rounded.d-flex {
        flex-direction: column;
    }

    .team-item.our-teams.rounded.d-flex img {
        width: 170px;
        height: auto;
        margin: auto;
    }
    .team-item.our-teams.rounded.d-flex .text-lg-start {}
    .service-banner-container {
        overflow: hidden;
    }

    .service-banner-container img{
        height: 300px;
        width: auto;
        object-fit: scale-down;
        margin-left: -270px;
    }
    .service-banner-container .service-banner-content {
        top: 15%;
        padding: 0 5px;
    }

    #card-service {
        padding: 10px !important;
    }

    #card-service ul#tabs-nav {
        display: flex;
        height: 90px;
    }

    #card-service ul#tabs-nav li{
        height: 89px;
        text-align: center;
        padding: 0;
        width: 33%;
    }
    #card-service ul#tabs-nav li img{
        display: block;
        clear: both;
        margin: auto;
    }
    #card-service ul#tabs-nav li span{
        display: block;
        margin-top: 10px;
    }
    #card-service #tabs-content .col-lg-6 img {
        margin-bottom: 30px;
    }
    .rounded {
        display: block !important;
    }
    .rounded a{}
    .articles .rounded a img{
        width: 100%;
    }
    .colum-right .rounded h2.blog-name {
        margin-top: 1rem !important;
    }
    .articles .py-5:first-child {
        padding-bottom: 0 !important;
    }
    .articles .colum-right .ms-3 {
        margin-left: 0 !important;
    }
    .careers-detail .careers-detail-box-search {}
    .careers-detail .careers-detail-box-search .box-search {
        max-width: 100%;
        flex-direction: column;
    }
    .careers-detail .careers-detail-box-search .box-search input {
        display: block;
        clear: both;
        width: 100%;
        margin-bottom: 10px;
        height: 55px;
        box-shadow: 0 0 1px 1px #ccc;
        border-radius: 10px;
    }
    .careers-detail .careers-detail-box-search .box-search button {}
    .careers-detail .container-xxl.py-5 {
        padding-top: 0 !important;
    }
    .job-details{}
    .job-details .intro-job{
        padding: 20px !important;
    }
    .job-details .intro-job{}
    .job-details .intro-job .row{
        margin-top: 0;
    }
    .job-details .intro-job .intro-job-title{
        margin-top: 20px;
    }
    .job-details .intro-job .intro-job-title .intro-job-inner{}
    .navbar.sticky-top {
        display: flex;
    }
    .navbar-light .navbar-toggler {
        padding: 0.25rem 0.55rem;
    }
    .navbar .navbar-brand img {
        width: 150px;
    }
    .order-1 {
        width: 100%;
        text-align: center;
        padding: 19px;
        margin-left: 12px;
    }
    .order-1 img{
        width: 100%;
        border-radius: 10px;
    }
}